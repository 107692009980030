import React from "react"
import { HeaderContainer, StyledHashtag } from "./styles"
import { TitleSvg } from "components/TitleSvg"
import PipeSvg from "assets/img/PageHeader/pipe.svg"
import PlusSvg from "assets/img/PageHeader/x.svg"
import { PageHeaderTitle } from "components/Typography"

const PageHeader = ({ children, imageUrl, hashtag }) => {
  return (
    <HeaderContainer image={imageUrl}>
      {!hashtag ? (
        <PageHeaderTitle>
          <TitleSvg image={PipeSvg} top="-20%" right="-5%" duration="3s" />
          {children}
          <TitleSvg image={PlusSvg} bottom="-50%" left="-5%" duration="5s" />
        </PageHeaderTitle>
      ) : (
        <StyledHashtag color="#fff">światbezbarier</StyledHashtag>
      )}
    </HeaderContainer>
  )
}
export { PageHeader }
