import styled from "styled-components"
import { SectionWrapper } from "shared/Styles/Wrappers"

export const Container = styled.div`
  padding: 96px 0;
  background-color: #f6f6f6;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 64px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0;
  }
`
export const Wrapper = styled(SectionWrapper)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    display: flex;
    flex-wrap: wrap;
  }
`

export const Item = styled.div`
  background-color: #fff;
  padding: 24px 48px 24px 24px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 24px;
  }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
       width: 100%;
    }
`

export const ImageBox = styled.div`
  width: 300px;
  border-radius: 15px;
  overflow: hidden;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 200px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: auto;
  }
    
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    .gatsby-image-wrapper {
      max-width: 100% !important;
    }
  }
`

export const TextBox = styled.div`
  width: calc(100% - 300px);
  padding-left: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: calc(100% - 200px);
    padding-left: 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    width: 100%;
    padding-left: 0;
    align-items: center;
    text-align: center;
  }
`

export const TextWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  padding-right: 48px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-right: 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    padding-right: 0;
  }
`

export const Date = styled.span`
  font-weight: 700;
  font-size: 14px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 12px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 10px;
  }
`

export const Title = styled.h3`
  font-weight: 700;
  font-size: 24px;
  margin-top: 4px;
  margin-bottom: 18px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 16px;
  }
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 1.6;
  font-weight: 300;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 14px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 12px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    margin-bottom: 24px;
  }
`
export const ButtonWrapper = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    a {
      white-space: nowrap;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    a {
      white-space: initial;
    }
  }
`
