import {Paragraph, SubHeader} from "components/Typography"
import styled                 from "styled-components"

export const Container = styled.div`
    margin-bottom: 48px;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        margin-bottom: 36px;
    }
`;

export const Title = styled(SubHeader)`
    display: block;
    margin-bottom: 24px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        margin-bottom: 18px;
    }
`;

export const Text = styled(Paragraph)`
    a {
        color: ${({theme}) => theme.colors.accentColor};

        &:hover {
            text-decoration: underline;
        }
    }
`;
