import React                    from "react"
import {Container, Text, Title} from "./styles"

export const TextBlock = ({data}) => {
  return (
    <Container>
      {data.title && <Title>{data.title}</Title>}
      {data.text && <Text>{data.text}</Text>}
    </Container>
  )
}
