import React from "react"

import {
  Container,
  Wrapper,
  Item,
  ImageBox,
  TextBox,
  Date,
  Title,
  Text,
  TextWrapper,
  ButtonWrapper,
} from "./styles"

import { PageHeader } from "components/PageHeader"
import { PrimaryButton } from "components/Buttons"
import useNewsData from "../../../shared/Hooks/News/useNewsData"

export const NewsList = () => {
  const data = useNewsData()

  return (
    <>
      <PageHeader>Aktualności</PageHeader>
      <Container>
        <Wrapper>
          {Object.values(data)
            .reverse()
            .map((news, i) => (
              <Item key={i}>
                <ImageBox>{news.detail_image}</ImageBox>
                <TextBox>
                  <TextWrapper>
                    <Date>{news.date}</Date>
                    <Title>{news.title}</Title>
                    <Text>{news.description}</Text>
                  </TextWrapper>
                  <ButtonWrapper>
                    <PrimaryButton to={news.slug} type="right_arrow">
                      Czytaj więcej
                    </PrimaryButton>
                  </ButtonWrapper>
                </TextBox>
              </Item>
            ))}
        </Wrapper>
      </Container>
    </>
  )
}
