import styled, { css } from "styled-components"
import { GlobalWrapper } from "shared/Styles/Wrappers"
import { Header, HashHeader } from "components/Typography"
const backgroundBlob = `url(
  "data:image/svg+xml,%3Csvg width='1643' height='350' viewBox='0 0 1643 350' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-166.957 1437.76C-97.9723 1499.67 0.287203 1536.13 91.9231 1515.05C202.658 1489.57 277.641 1390.33 331.226 1292.73C384.81 1195.13 430.569 1087.97 519.946 1019.37C679.803 896.67 914.411 941.211 1104.34 869.856C1228.35 823.278 1330.68 724.598 1379.5 604.472C1561.42 156.988 1036.69 -248.506 618.977 -255.04C281.781 -260.293 -39.5351 -61.3645 -221.381 215.122C-394.799 478.834 -423.078 836.043 -334.166 1136.12C-327.186 1159.68 -319.485 1182.91 -311.062 1205.71C-279.319 1291.6 -235.893 1375.87 -166.957 1437.76Z' fill='%236121B2'/%3E%3C/svg%3E%0A"
)`
const HeaderImageHeight = css`
  ${({ image }) =>
    image &&
    css`
      height: 200px;
      background-image: ${image};
      background-position: 50% 0%;
      background-repeat: no-repeat;
      background-size: cover;
    `}
`
export const HeaderContainer = styled(GlobalWrapper)`
  background: #5618a4;
  background-image: ${({ image }) =>
    image ? `url(${image})` : backgroundBlob};
  background-size: cover;
  background-position: left top;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  ${HeaderImageHeight}
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 160px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    height: 120px;
  }
`
export const Title = styled(Header)`
  font-size: 40px;
  color: ${({ theme }) => theme.colors.white};
`
export const StyledHashtag = styled(HashHeader)`
  font-size: 48px;
  color: ${({ theme }) => theme.colors.fontPrimary};
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    font-size: 30px;
  }
`
