import {NewsSlider} from "components/NewsSlider"
import {PageHeader} from "components/PageHeader"
import SEO from "components/seo"

import {Header} from "components/Typography"
import React from "react"

import {Container, Date, HeaderWrapper, HeroImage, TextColumn, TextWrapper, Wrapper,} from "./styles"

export const NewsLayout = ({data, children}) => {
  return (
    <>
      <SEO title={data.title} description={data.description}/>
      <PageHeader>Aktualności</PageHeader>
      <Container>
        <HeroImage>{data.hero_image}</HeroImage>
        <Wrapper>
          <HeaderWrapper>
            <Header as="h1">{data.title}</Header>
            <Date>{data.date}</Date>
          </HeaderWrapper>
          <TextWrapper>
            <TextColumn>
              {children}
            </TextColumn>
          </TextWrapper>
        </Wrapper>
        <NewsSlider title="Inne aktualności"/>
      </Container>
    </>
  )
}
